<template>
	<div class="mainBox">
		<img :src="banner" style="width: 100%;height: auto;" />
		<div class="formBox">
			<van-field rows="2" autosize type="textarea" placeholder="「粘贴识别」智能拆分姓名/电话/地址等信息" v-model="addressMess">
			</van-field>
			<div style="text-align: right;">
				<van-button size="small" type="primary" round color="#000000"
					@click="ocrAddress">{{isp ? '粘贴并识别' : '识别地址'}}</van-button>

			</div>
		</div>
		<div class="formBox">
			<van-form>
				<div class="title">请完善收件信息</div>
				<van-cell-group inset>
					<van-field v-model="cardData.card" name="username" label="卡号" placeholder="请输入收件人姓名" type="text"
						clearable label-width="60px" readonly />
					<van-field v-model="username" name="username" label="姓名" placeholder="请输入收件人姓名" type="text"
						clearable label-width="60px" required />
					<van-field v-model="tel" name="tel" label="电话" placeholder="请输入收件手机号" type="tel" clearable
						label-width="60px" required />
					<van-field v-model="pca" name="pca" label="地址" placeholder="请选择省市区" is-link readonly
						@click="show = true" label-width="60px" required />
					<input v-model="province" type="hidden" />
					<input v-model="city" type="hidden" />
					<input v-model="area" type="hidden" />
					<van-field v-model="address" name="address" label="详细" type="text" placeholder="请补充详细地址" clearable
						label-width="60px" required />
					<van-field v-model="content" name="content" label="备用电话" placeholder="请输入备用手机号" type="tel" clearable
						label-width="90px" required />
				</van-cell-group>
				<div style="margin: 16px;">
					<van-button round block type="primary" native-type="submit" color="#000000" @click="change"
						:loading="loading">
						立即兑换
					</van-button>
					<van-button round block type="primary" native-type="submit" color="#b6b6b6" @click="logout"
						style="margin-top: 10px;">
						取消兑换
					</van-button>
				</div>
			</van-form>
		</div>
		<div class="footer">&copy;Sanmark<div class="istero" @click="toistero">起零公司提供技术支持</div>
		</div>
		<van-action-sheet v-model:show="show" :closeable="false" title="">
			<van-area v-model="value" title="请选择省市区" :area-list="areaList" @confirm="confirm" @cancel="cancel"
				@change="addChange" />
		</van-action-sheet>
		<van-popup v-model:show="show2" :style="{ padding: '60px' }" round closeable
			@click-close-icon="onClickCloseIcon" :close-on-click-overlay="false">
			<div style="margin-bottom: 10px;"><van-icon name="success" size="70px" /></div>
			<div style="font-size: 15px;margin-bottom: 10px;">家油卡兑换成功</div>

			<van-button round type="primary" @click="view" style="margin-top: 10px;margin-bottom: 20px;"
				color="#000000">
				查看订单详情
			</van-button>

			<div style="font-size: 13px;">{{$tipstext}}</div>
			<div style="background: #f1f1f1;padding: 10px;margin-top: 40px;font-size: 13px;" class="tag-read"
				data-clipboard-text="YHD8624" @click.native="copyEx()">客服微信号：YHD8624</div>
			<div><img src="../assets/qrcode.jpg" style="width: 60%;margin-top: 30px;" /></div>
			<div style="font-size: 13px;color: #222222;">长按二维码识别</div>
		</van-popup>

	</div>
</template>

<script>
	import {
		useRouter,
		useRoute
	} from 'vue-router'
	import {
		showToast,
		showDialog
	} from 'vant';
	import {
		showConfirmDialog
	} from 'vant';
	import AddressParse from 'address-parse';

	import {
		areaList
	} from '@vant/area-data';

	import {
		verifyOrder,
		updateData,
		getCardData,
		getBanner,
		ocrAddress
	} from '@/api/home';
	import Clipboard from 'clipboard';
	export default {
		name: 'App',
		data() {
			return {
				username: "",
				tel: "",
				value: "",
				banner: "",
				addressMess: "",
				address: "",
				pca: "",
				cardData: {
					card: "-"
				},
				content: "",
				loading: false,
				show: false,
				show2: false,
				isp: true,
				$tipstext: "",
			}
		},
		created() {
			let that = this
			that.verLogin();
			that.getBanner()
		},

		setup() {
			let that = this


			return {
				areaList
			};

		},
		methods: {
			view() {
				let that = this
				that.$router.push({
					path: "/order-detail",
					query: {
						sn: that.ordersn
					}
				})

			},
			copyEx() {
				var clipboard = new Clipboard('.tag-read')
				clipboard.on('success', e => {
					showToast("微信号复制成功")
					clipboard.destroy() // 释放内存
				})
				clipboard.on('error', e => {
					showToast("复制失败")
					clipboard.destroy() // 释放内存
				})
			},
			getBanner() {
				let that = this
				getBanner({}).then(res => {
					if (res.code == 200) {
						that.banner = res.data.zb_img
						that.$tipstext = res.text.zt_text
					}
				})
			},
			verLogin() {
				let that = this
				if (!localStorage.getItem("access_token")) {
					that.clearLogin()
				} else {
					that.getCardData()
				}
			},
			getCardData() {
				let that = this
				getCardData({}).then(res => {
					if (res.code == 300) {
						that.clearLogin()
					} else if (res.code == 200) {
						that.cardData = res.data
						if (res.data.status > 0) {
							that.$router.push({
								path: "/order"
							})
						}
					}

				})
			},

			cancel() {
				this.show = false
			},
			addChange(e) {
				console.log(e)
			},
			confirm(e) {
				let that = this
				that.pca = e.selectedOptions[0].text + ' ' + e.selectedOptions[1].text + ' ' + e.selectedOptions[2]
					.text
				that.province = e.selectedOptions[0].text
				that.city = e.selectedOptions[1].text
				that.area = e.selectedOptions[2].text
				that.show = false
			},
			logout() {
				let that = this
				showConfirmDialog({
						title: '提示',
						message: '是否确认取消兑换？',
					})
					.then(() => {
						that.clearLogin()
					})
					.catch(() => {
						// on cancel
					});


			},
			clearLogin() {
				let that = this
				localStorage.clear();
				localStorage.setItem("access_token", "");
				that.$router.push({
					path: "/"
				})
			},
			onClickCloseIcon() {
				let that = this
				that.clearLogin()
			},
			ocrAddress() {
				let that = this
				try {
					navigator.clipboard.readText().then((v) => {
						that.addressMess = v;
						that.callOcrAddress()
					})
				} catch (e) {
					if (that.addressMess == '') {
						that.isp = false
						showToast("粘贴失败，请手动粘贴后再点击识别")
					} else {
						that.callOcrAddress()
					}
				}
			},
			callOcrAddress() {
				let that = this
				if (that.addressMess != '') {
					const result = AddressParse.parse(that.addressMess);
					console.log(result)
					that.username = result[0].name
					that.tel = result[0].mobile
					that.address = result[0].details
					that.value = result[0].code
					that.pca = result[0].province + ' ' + result[0].city + ' ' +
						result[0].area
					that.province = result[0].province
					that.city = result[0].city
					that.area = result[0].area
				}
			},
			change() {
				let that = this

				if (!that.username) {
					showToast("收件姓名不能为空")
					return false
				} else if (!that.tel) {
					showToast('收件人电话不能为空')
					return false
				} else if (!that.pca) {
					showToast('请选择省市区')
					return false
				} else if (!that.address) {
					showToast('请补充详细地址')
					return false
				} else if (!that.content) {
					showToast('请输入备用收件电话')
					return false
				} else {

					showConfirmDialog({
							title: '提示',
							message: '是否确认信息无误并兑换？',
						})
						.then(() => {
							that.loading = true
							updateData({
								username: that.username,
								tel: that.tel,
								pca: that.pca,
								province: that.province,
								city: that.city,
								area: that.area,
								address: that.address,
								content: that.content
							}).then(res => {
								that.loading = false
								if (res.code == 200) {
									that.show2 = true
									that.ordersn = res.data
									// showDialog({
									// 		title: '提示',
									// 		message: '家油卡兑换成功'
									// 	})
									// 	.then(() => {
									// 		that.clearLogin()
									// 	})
								}
							})
						})
						.catch(() => {
							// on cancel
						});

				}
			}
		}


	}
</script>
<style>
	body {
		background: rgb(24, 24, 24);
	}

	.mainBox {
		max-width: 800px;
		margin-left: auto;
		margin-right: auto;
		text-align: center;
	}



	.mainBox .formBox {
		width: 85%;
		padding: 5px;
		text-align: center;
		background: #ffffff;
		margin-left: auto;
		margin-right: auto;
		margin-top: 20px;
		border-radius: 10px;

	}

	.mainBox .title {
		font-size: 14px;
		padding: 15px 0px;
	}

	.mainBox .footer {
		font-size: 12px;
		padding: 30px 0px;
		color: #676767;
		text-align: center;
	}

	.mainBox .footer .istero {
		font-size: 11px;
		border-bottom: #4c4c4c solid 1px;
		margin-top: 5px;
		width: fit-content;
		margin-left: auto;
		margin-right: auto;
	}
</style>